.wrapper {
  max-width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: auto;
}

.bannerBlockContainer {
  position: relative;
}

.heading {
  font-family: 'Lato', serif;
  color: #fff;
  font-size: 32px;
  font-weight: 400;
  line-height: 39px;
  margin-bottom: 15px;
}

.darkTextColor {
  color: #474747;
}

.bannerBlockMobileImage {
  display: block;
}

.bannerBlockDesktopImage {
  display: none;
}

@media (min-width: 767px) {
  .heading {
    font-size: 40px;
    line-height: 48px;
  }
  .bannerBlockDesktopImage {
    display: block;
  }
  .bannerBlockMobileImage {
    display: none;
  }
}

@media (min-width: 1024px) {
  .wrapper {
    max-width: 1440px;
    padding-left: 4rem;
    padding-right: 4rem;
    margin: auto;
  }
}
