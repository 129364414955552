.wrapper {
  max-width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: auto;
}

.brandsBlock {
  font-family: 'Lato', sans-serif;
  margin: 30px auto 30px auto;
}

.heading {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: rgba(39, 39, 39, 1);
  text-align: center;
  margin-bottom: 30px;
}

.grid__item {
  cursor: pointer;
}

@media (min-width: 1024px) {
  .wrapper {
    max-width: 1440px;
    padding-left: 4rem;
    padding-right: 4rem;
    margin: auto;
  }

  .heading {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 60px;
  }

  .brandsBlock {
    margin: 80px auto 80px auto;
  }
}
