.wrapper {
  max-width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: auto;
}
.grid {
  margin: 40px 0px;
}
.grid__heading {
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.grid__body {
  display: grid;
  grid-gap: 20px;
}

.grid__4Cols {
  grid-template-columns: 1fr 1fr;
}

.grid__6Cols {
  grid-template-columns: 1fr 1fr;
}

.grid__item {
  cursor: pointer;
}

.grid__item img {
  max-width: 100%;
}

.showMoreBtn {
  display: block;
  margin: auto;
  width: 100%;
  border: 1px solid #000;
  border-radius: 3px;
  padding: 10px 50px;
  cursor: pointer;
  background: none;
}

.grid__itemTitle {
  color: rgba(71, 71, 71, 1);
  font-weight: 700;
  margin-top: 15px;
  text-transform: uppercase;
}

.grid__itemTitle h3 {
  font-size: 13px;
}

@media (min-width: 767px) {
  .grid__heading {
    font-size: 24px;
  }

  .grid__itemTitle h3 {
    font-size: 16px;
  }

  .grid__4Cols {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .grid__6Cols {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .showMoreBtn {
    width: 220px;
    margin-top: 20px;
  }
}

@media (min-width: 1024px) {
  .wrapper {
    max-width: 1440px;
    padding-left: 4rem;
    padding-right: 4rem;
    margin: auto;
  }

  .grid {
    margin: 80px 0px;
  }
}
