.root {
  margin: 0;
  padding: 0;
}

.wrapper {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: auto;
}

@media (min-width: 1024px) {
  .wrapper {
    max-width: 1440px;
    padding-left: 4rem;
    padding-right: 4rem;
    margin: auto;
  }
}

.textTable {
  text-align: left;
  margin: 0 0 32px;
  color: rgb(71, 71, 71);
}

.textTable__heading {
  color: rgb(39, 39, 39);
  font-weight: 700;
  margin-bottom: 16px;
  text-transform: uppercase;
}

.textTable__heading h5 {
  font-size: 13px;
}

.textTable__row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 8px;
  font-size: 13px;
  line-height: 24px;
}

@media (max-width: 767px) {
  .textTable__row {
    grid-template-columns: 50% 50%;
  }
}

.textTable__head {
  padding-right: 130px;
  font-weight: 400;
}

@media (max-width: 767px) {
  .textTable__head {
    grid-column: 1 / 2;
  }
}

.textTable__data {
  font-weight: 400;
}

@media (max-width: 767px) {
  .textTable__data {
    grid-column: 1 / 2;
  }
}

/*@media (min-width: 767px) {*/
/*  .textTable {*/
/*    margin: 20px 0;*/
/*  }*/
/*}*/
