.wrapper {
  max-width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: auto;
}

.modalBlock {
  margin: 0 0 32px;
}

.modal__backdrop {
  background: rgba(70, 69, 68, 0.4);
  bottom: 0;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
}

.modal__container {
  position: relative;
  height: 100%;
}

.modal__body {
  background: rgba(255, 255, 255, 1);
  border-radius: 5px;
  max-width: 100%;
  padding: 30px 40px;
  width: 300px;
  position: absolute;
  line-height: 24px;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.modal__heading {
  text-align: center;
  font-weight: 700;
  color: rgba(39, 39, 39, 1);
  margin-bottom: 30px;
}

.modal__heading h5 {
  font-size: 13px;
}

.modal__text p {
  text-align: center;
  color: rgba(71, 71, 71, 1);
  font-weight: 400;
  margin-bottom: 20px;
}

.modal__text ul {
  list-style-type: disc;
  color: rgba(71, 71, 71, 1);
  font-weight: 400;
  margin: 25px;
}

.modal__text a {
  text-decoration: underline;
}

.modal__closeBtn {
  position: absolute;
  top: -1px;
  padding: 15px;
  right: 6px;
  cursor: pointer;
  border: none;
  background: none;
  color: rgba(70, 69, 68, 1);
}

.heading {
  color: rgb(39, 39, 39);
  font-weight: 700;
  margin-bottom: 16px;
  text-transform: uppercase;
}

.heading h5 {
  font-size: 13px;
}

.text {
  color: rgb(71, 71, 71);
  text-align: left;
  line-height: 24px;
  font-weight: 400;
}

.text p {
  display: inline;
}

.openModalBtn {
  text-decoration: underline;
  cursor: pointer;
}

@media (min-width: 600px) {
  .modal__body {
    width: 560px;
  }
}

@media (min-width: 1024px) {
  .wrapper {
    max-width: 1440px;
    padding-left: 4rem;
    padding-right: 4rem;
    margin: auto;
  }
}
