.wrapper {
  margin: 38px 0;
  max-width: 420px;
}

@media (min-width: 1024px) {
  .wrapper {
    max-width: 1440px;
    margin: 36px auto;
  }
}

.blockItem {
  padding: 30px 0;
  border-bottom: 1px solid rgba(201, 201, 201, 1);
}

.blockItem:first-child {
  border-top: 1px solid rgba(201, 201, 201, 1);
}

.blockItemHeading {
  cursor: pointer;
}

.blockItemToggle {
  display: flex;
  align-items: baseline;
}

.blockItemToggle__img {
  height: 16px;
  width: auto;
  margin-right: 15px;
}

.blockItemToggle__text {
  font-size: 13px;
  line-height: 24px;
  color: rgba(71, 71, 71, 1);
  font-weight: 400;
}

.blockItemToggle__heading {
  margin-bottom: 10px;
}

.blockItemPanel {
  margin: 15px 0px 0px 35px;
  font-weight: 400;
  color: rgba(71, 71, 71, 1);
  line-height: 1.5rem;
}

.blockItemPanel a {
  text-decoration: underline;
}

.blockItemPanel p {
  margin-bottom: 20px;
}

.blockItemPanel ul {
  list-style-type: disc;
  margin: 10px 0px 10px 20px;
}

.blockItemPanel ol {
  list-style-type: decimal;
  margin: 10px 0px 10px 20px;
}

.blockItemButton:after {
  content: url(../../public/icons/plusIcon.svg);
}

.blockItemButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blockItemButton[aria-expanded='true']::after {
  content: url(../../public/icons/minusIcon.svg);
}

