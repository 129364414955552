.wrapper {
    max-width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: auto;
  }
  
  .bannerContainer {
    position: relative;
  }
  
  .heading {
    font-family: 'Lato', serif;
    color: #fff;
    font-size: 32px;
    font-weight: 400;
    line-height: 39px;
    margin-bottom: 15px;
  }
  
  .darkTextColor {
    color: #474747;
  }
  
  .bannerMobileImage {
    display: block;
  }
  
  .bannerDesktopImage {
    display: none;
  }

  .mobile {
    display: block;
  }
  
  .desktop {
    display: none;
  }

  div.margin-top-bottom {
    margin: 40px 0;
  }
  div.margin-bottom {
    margin-bottom: 10px;
  }
  
  @media (min-width: 767px) {
    .mobile {
        display: none;
    }
    .desktop {
        display: block;
    }
    .heading {
      font-size: 40px;
      line-height: 48px;
    }
    .bannerDesktopImage {
      display: block;
    }
    .bannerMobileImage {
      display: none;
    }
  }
  
  @media (min-width: 1024px) {
    .wrapper {
      max-width: 1440px;
      padding-left: 4rem;
      padding-right: 4rem;
      margin: auto;
    }
  }

  /* FROM GLOBAL STYLE ELEMENT */
  .buttonArrow {
    padding-left: 5px !important;
    padding-top: 3px;
  }

  .simpleBtn,
  .simpleBtnDark {
    font-size: 16px;
    color: #fff;
    display: flex;
    align-items: center;
  }

  .simpleBtn:hover {
    text-decoration: underline;
    text-underline-position: under;
  }

  .simpleBtnDark:hover {
    text-decoration: underline;
    text-underline-position: under;
    color: #474747;
  }

  /* FROM LOCAL STYLE ELEMENT  */
  .contentBlock {
    box-sizing: border-box;
    position: absolute;
    height: 100%;
    width: 90%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }

  .content {
    word-break: normal;
  }

  /* New additions to .contentBlock styling */
  .contentBlock.center-top {
    justify-content: flex-start;
    align-items: center;
    padding-top: 2rem;
  }

  .contentBlock.center-middle {
    justify-content: center;
    align-items: center;
  }

  .contentBlock.center-bottom {
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 2rem;
  }

  .contentBlock.left-top {
    justify-content: flex-start;
    padding-top: 2rem;
  }

  .contentBlock.left-middle {
    justify-content: center;
  }

  .contentBlock.left-bottom {
    justify-content: flex-end;
    padding-bottom: 2rem;
  }

  .contentBlock.right-top {
    justify-content: flex-start;
    align-items: end;
    padding-top: 2rem;
  }

  .contentBlock.right-middle {
    justify-content: center;
    align-items: end;
  }

  .contentBlock.right-bottom {
    justify-content: flex-end;
    align-items: end;
    padding-bottom: 2rem;
  }

  /* If content-spacing set to space-between */
  .contentBlock.center-middle.space-between > :first-child {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .contentBlock.center-middle.space-between > :last-child {
    position: absolute;
    bottom: 0;
    padding-bottom: 2rem;
  }


