.wrapper {
  max-width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: auto;
}

.faqBlock {
  text-align: left;
  margin: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.faqBlockVertical {
  text-align: left;
  margin: 40px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.faqBlock__heading {
  font-size: 32px;
  line-height: 40px;
  color: rgb(71, 71, 71);
  font-weight: 400;
  margin-bottom: 12px;
}

.faqBlock__text {
  color: rgb(39, 39, 39);
}

.faqBlock__text a {
  text-decoration: underline;
  color: rgb(39, 39, 39);
  font-weight: 400;
}

.faqBlockCol {
  width: 100%;
  z-index: 1;
}

.accPanel {
  font-weight: 400;
  margin-top: 20px;
  line-height: 1.5rem;
  color: rgba(71, 71, 71, 1);
}

.accPanel p {
  margin-bottom: 20px;
}

.accPanel a {
  text-decoration: underline;
}

.accPanel ul {
  list-style-type: disc;
  margin: 10px 0px 10px 20px;
}

.accPanel ol {
  list-style-type: decimal;
  margin: 10px 0px 10px 20px;
}

.accButton {
  color: rgb(39, 39, 39);
  font-weight: 400;
  border-bottom: 1px solid rgb(201, 201, 201);
  padding: 24px 0px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.accButton:after {
  content: url(../../public/icons/plusIcon.svg);
}

.accButton[aria-expanded='true']::after {
  content: url(../../public/icons/minusIcon.svg);

  /* margin-top: -10px; */
}

@media (max-width: 767px) {
  .faqBlock__heading {
    margin-top: 40px;
    font-size: 26px;
    line-height: 32px;
  }
}

@media (min-width: 767px) {
  .faqBlock {
    flex-direction: row;
    justify-content: space-between;
  }

  .faqBlock {
    margin: 20px 0;
  }
}

@media (min-width: 1024px) {
  .wrapper {
    max-width: 1440px;
    padding-left: 4rem;
    padding-right: 4rem;
    margin: auto;
  }
}
