.wrapper {
  max-width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2.4rem;
  margin: auto;
}

.textBlock {
  text-align: left;
}

.textBlock li {
  list-style-type: disc;
  list-style-position: inside;
}

.alignCenter * {
  text-align: center;
  max-width: 870px;
  margin: auto;
}

.textBlock__heading {
  color: rgb(39, 39, 39);
  font-weight: 700;
  margin-bottom: 16px;
  text-transform: uppercase;
}

.textBlock__heading h5 {
  font-size: 13px;
}

.textBlock__heading h1 {
  font-size: 24px;
  font-weight: 400;
}

.textBlock__description {
  color: rgb(71, 71, 71);
  text-align: left;
  line-height: 24px;
  font-weight: 400;
}

.textBlock__description a {
  text-decoration: underline;
}

.textBlock__description p {
  margin-bottom: 20px;
}

@media (min-width: 767px) {
}

@media (min-width: 1024px) {
  .wrapper {
    max-width: 1440px;
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 2.5rem;
    margin: auto;
  }
}
