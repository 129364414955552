.wrapper {
  max-width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: auto;
}

.downloadBlock {
  font-family: 'Lato', sans-serif;
  text-align: left;
  margin: 0 0 32px;
}

.downloadBlock__heading {
  color: rgb(39, 39, 39);
  font-weight: 700;
  margin-bottom: 16px;
  text-transform: uppercase;
}

.downloadBlock h5 {
  font-size: 13px;
}

.downloadBlock__link {
  display: flex;
  align-items: baseline;
  text-decoration: none;
  color: rgb(71, 71, 71);
}

.downloadBlock__link p {
  font-weight: 700;
}

.downloadBlock__link img {
  height: 15px;
  margin-right: 10px;
}

/*@media (min-width: 767px) {*/
/*  .downloadBlock {*/
/*    margin: 40px 0px;*/
/*  }*/
/*}*/

@media (min-width: 1024px) {
  .wrapper {
    max-width: 1440px;
    padding-left: 4rem;
    padding-right: 4rem;
    margin: auto;
  }
}
