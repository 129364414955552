.imageContainer {
  display: relative;
  margin: 0 auto;
  max-width: 336px;
  max-height: 336px;
}
.imageDesktop {
  display: none;
}

.imageMobile {
  display: block;
  position: relative;
  margin: 0 auto;
}

@media (min-width: 1024px) {
  .imageContainer {
    display: relative;
    margin: 0 auto;
    max-width: 1312px;
    max-height: 560px;
  }

  .imageDesktop {
    display: block;
    position: relative;
  }

  .imageMobile {
    display: none;
  }
}
