.wrapper {
  max-width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: auto;
}

.readMoreBlock {
  font-family: 'Lato', sans-serif;
  max-width: 1000px;
  margin: 30px auto 30px auto;
}

.heading {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 400;
  text-align: center;
  margin-bottom: 16px;
  color: rgb(39, 39, 39);
  text-transform: uppercase;
}

.text,
.moreText {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
}

.text p,
.moreText p {
  margin-bottom: 16px;
}

.addDots p:last-child:after {
  content: '...';
}

.readMoreBtn {
  text-align: center;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
}

.btnText {
  padding-right: 7px;
}

.minusIcon {
  margin-top: 2px !important;
  cursor: pointer;
}

.btn {
  margin-right: 10px;
}

.firstText:after {
  content: '...';
}

@media (min-width: 1024px) {
  .wrapper {
    max-width: 1440px;
    padding-left: 4rem;
    padding-right: 4rem;
    margin: auto;
  }

  .readMoreBlock {
    margin: 80px auto 80px auto;
  }
}
