.wrapper {
  max-width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: auto;
}
.homeCollectionsRow {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.homeCollectionsRow {
  margin: 40px 0px;
}
.grid__heading {
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #272727;
}

.grid__body {
  display: grid;
  grid-gap: 20px;
}

.grid__3Cols {
  grid-template-columns: 1fr 1fr;
}

.grid__4Cols {
  grid-template-columns: 1fr 1fr;
}

.grid__item {
  cursor: pointer;
}

.showMoreBtn {
  display: block;
  margin: auto;
  width: 100%;
  border: 1px solid #000;
  border-radius: 3px;
  padding: 10px 50px;
  cursor: pointer;
  background: none;
}

.grid__itemTitle {
  color: rgba(71, 71, 71, 1);
  font-weight: 700;
  margin-top: 15px;
  text-transform: uppercase;
}

.grid__itemTitle h3 {
  font-size: 13px;
}

@media (min-width: 767px) {
  .grid__heading {
    font-size: 24px;
    line-height: 32px;
    padding-right: 30px;
    color: #272727;
  }

  .grid__itemTitle h3 {
    font-size: 16px;
  }

  .grid__3Cols {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .grid__4Cols {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .showMoreBtn {
    width: 220px;
  }

  .grid__item {
    margin-bottom: 0px;
  }
}

@media (min-width: 1024px) {
  .wrapper {
    max-width: 1440px;
    padding-left: 4rem;
    padding-right: 4rem;
    margin: auto;
  }

  .homeCollectionsRow {
    /* display: flex; */
    flex-direction: row;
    margin: 80px 0px;
  }
}
