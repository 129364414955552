.heading {
  font-family: Lato;
  font-style: regular;
  font-size: 32px;
  line-height: 39px;
  margin-bottom: 16px;
}

.headingLeft {
  position: absolute;
  top: 210px;
  left: 24px;
  width: 288px;
}
.headingLeftBetween {
  position: absolute;
  top: 100px;
  left: 24px;
  width: 288px;
}

.contentLeft {
  font-size: 16px;
  max-width: 288px;
}

.contentLeftBetween {
  font-size: 16px;
  max-width: 288px;
  padding-top: 120px;
}
.contentCentreBetween {
  padding-top: 90px;
}
.headingCentre {
  position: absolute;
  text-align: center;
  top: 25%;
}
.headingCentreBetween {
  position: absolute;
  text-align: center;
  top: 25%;
}

.buttonCentreBetween {
  margin: auto;
  margin-top: 10px;
  padding-top: 90px;
}

.buttonCentre {
  margin: auto;
}

.buttonLeft {
  padding-top: 5px;
}
.buttonLeftBetween {
  padding-top: 90px;
}
@media (min-width: 1024px) {
  .heading {
    font-size: 40px;
  }
  .headingLeft {
    position: absolute;
    top: 210px;
    left: 112px;
    width: 528px;
    height: 96px;
    padding: 0px;
  }

  .headingLeftBetween {
    position: absolute;
    top: 210px;
    left: 112px;
    width: 528px;
    height: 96px;
    padding: 0px;
  }

  .contentLeft {
    font-size: 16px;
    max-width: 528px;
    padding-top: 16px;
  }

  .contentLeftBetween {
    font-size: 16px;
    max-width: 288px;
    padding-top: 180x;
  }
  .headingCentre {
    left: 0;
    right: 0;
    padding-top: 5%;
    text-align: center;
  }

  .headingCentreBetween {
    left: 0;
    right: 0;
    padding-top: 5%;
    text-align: center;
  }

  .contentCentre {
    padding-top: 16px;
  }

  .contentCentreBetween {
    padding-top: 150px;
  }

  .buttonCentreBetween {
    padding-top: 150px;
    margin: auto;
  }
}
