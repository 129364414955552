.wrapper {
  max-width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: auto;
}

.videoBlockContainer {
  margin: 40px 0px;
}

@media (min-width: 767px) {
}

@media (min-width: 1024px) {
  .wrapper {
    max-width: 1440px;
    padding-left: 4rem;
    padding-right: 4rem;
    margin: auto;
  }
  .videoBlockContainer {
    margin: 80px 0px;
  }
}
