.wrapper {
  max-width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: auto;
}

.imagesBlock {
  display: flex;
  margin: 15px 0;
}

.imgItem {
  float: left;
  width: auto;
  padding-right: 15px;
}

.imagesBlock::after {
  content: '';
  clear: both;
  display: table;
}

.imgItem .img {
  width: 100%;
}

@media (min-width: 767px) {
  .imagesBlock {
    margin: 0 0 32px;
  }
}

@media (min-width: 1024px) {
  .wrapper {
    max-width: 1440px;
    padding-left: 4rem;
    padding-right: 4rem;
    margin: auto;
  }
}
